import { html, render } from 'lit-html';
import * as outputStyles from 'url:../../output.css';
import * as brandStyles from 'url:../../brand.css';

class CustomFooter extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: "open" });
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const template = html`
      <link
        href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;900&display=swap"
        rel="stylesheet"
      />
      <link href="${outputStyles}" rel="stylesheet">
      <link href="${brandStyles}" rel="stylesheet">
      <footer class="bg-gray-800">
        <div
          class="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8"
        >
          <div class="flex justify-center space-x-6 md:order-2">
            <a
              href="https://brainedge.llc"
              target="_blank"
              class="text-white hover:text-gray-400"
            >
              <span class="sr-only">Brain Edge</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/discoversolaris/"
              target="_blank"
              class="text-white hover:text-gray-400"
            >
              <span class="sr-only">Instagram</span>
              <svg
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div class="mt-8 md:order-1 md:mt-0 future-font flex flex-wrap gap-4 justify-center">
            <a
              href="/privacy"
              class="text-white text-xs hover:text-gray-400 px-2"
            >
              Privacy
            </a>
            <a
              href="/cookies"
              class="text-white text-xs hover:text-gray-400 px-2"
            >
              Cookies
            </a>
            <a href="javascript:UC_UI.showSecondLayer();" id="usercentrics-psl" class="text-white text-xs hover:text-gray-400 px-2">
              Privacy Settings
            </a>
            <a
              href="/terms-of-service"
              class="text-white text-xs hover:text-gray-400 px-2"
            >
              Terms of Service
            </a>
            <a
              href="/disclaimer"
              class="text-white text-xs hover:text-gray-400 px-2"
            >
              Disclaimer
            </a>
          </div>
          <div class="mt-8 md:order-1 md:mt-0 future-font">
            <p class="text-center text-xs leading-5 text-white">
              &copy; 2025 Brain Edge, LLC. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    `;

    render(template, this.shadow);
  }
}

customElements.define("custom-footer", CustomFooter);
