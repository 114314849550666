import { html, render } from 'lit-html';
import * as navStyles from 'url:./style.css';
import * as outputStyles from 'url:../../output.css';
import * as brandStyles from 'url:../../brand.css';
import * as solarisLogo from 'url:../../assets/SOLARIS-01-LOGO.png';

class NavBar extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: "open" });
  }

  get mainText() {
    return this.getAttribute("mainText");
  }

  set mainText(val) {
    this.setAttribute("mainText", val);
  }

  static get observedAttributes() {
    return ["mainText"];
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.render();

    this.toggleSheetDropdown = this.toggleSheetDropdown.bind(this);
    this.toggleVngrdDropdown = this.toggleVngrdDropdown.bind(this);
    this.toggleSheetMobileDropdown = this.toggleSheetMobileDropdown.bind(this);
    this.toggleVngrdMobileDropdown = this.toggleVngrdMobileDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.toggleCloseMobileMenu = this.toggleCloseMobileMenu.bind(this);
    this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
    this.toggleMobileProfileMenu = this.toggleMobileProfileMenu.bind(this);

    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    mediaQuery.addEventListener('change', (e) => {
      if (e.matches) {
        this.closeMobileMenu();
      }
    });

    setTimeout(() => {
      document.addEventListener("click", this.handleClickOutside);

      const elements = {
        "#sheets-button": this.toggleSheetDropdown,
        "#vngrd-button": this.toggleVngrdDropdown,
        "#sheets-mobile-button": this.toggleSheetMobileDropdown,
        "#vngrd-mobile-button": this.toggleVngrdMobileDropdown,
        "#mobile-menu-button": this.toggleMobileMenu,
        "#close-menu-icon": this.toggleCloseMobileMenu,
        "#profile-button": this.toggleProfileMenu,
        "#mobile-profile-button": this.toggleMobileProfileMenu
      };

      Object.entries(elements).forEach(([selector, handler]) => {
        const element = this.shadow.querySelector(selector);
        if (element) {
          element.addEventListener("click", handler);
        }
      });
    }, 0);
  }

  toggleSheetDropdown(e) {
    e.stopPropagation();
    const dropdown = this.shadow.querySelector("#sheet-dropdown");
    dropdown.classList.toggle("hidden");
  }

  toggleVngrdDropdown(e) {
    e.stopPropagation();
    const dropdown = this.shadow.querySelector("#vngrd-dropdown");
    dropdown.classList.toggle("hidden");
  }

  toggleSheetMobileDropdown(e) {
    e.stopPropagation();
    const dropdown = this.shadow.querySelector("#sheet-mobile-dropdown");
    dropdown.classList.toggle("hidden");
  }

  toggleVngrdMobileDropdown(e) {
    e.stopPropagation();
    const dropdown = this.shadow.querySelector("#vngrd-mobile-dropdown");
    dropdown.classList.toggle("hidden");
  }

  toggleMobileMenu(e) {
    e.stopPropagation();
    const mobileMenu = this.shadow.querySelector("#mobile-menu");
    const overlay = document.createElement('div');
    overlay.className = 'mobile-menu-overlay';
    
    const handleOutsideClick = (e) => {
      if (!mobileMenu.contains(e.target)) {
        this.closeMobileMenu();
        document.removeEventListener('click', handleOutsideClick);
      }
    };

    if (mobileMenu.classList.contains('hidden')) {
      mobileMenu.classList.remove('hidden');
      this.shadow.appendChild(overlay);
      setTimeout(() => {
        document.addEventListener('click', handleOutsideClick);
      }, 0);
    }
  }

  closeMobileMenu() {
    const mobileMenu = this.shadow.querySelector("#mobile-menu");
    const overlay = this.shadow.querySelector('.mobile-menu-overlay');
    mobileMenu.classList.add('hidden');
    if (overlay) {
      overlay.remove();
    }
  }

  toggleCloseMobileMenu(e) {
    e.stopPropagation();
    this.closeMobileMenu();
  }

  handleClickOutside(e) {
    const sheetDropdown = this.shadow.querySelector("#sheet-dropdown");
    const vngrdDropdown = this.shadow.querySelector("#vngrd-dropdown");
    const profileMenu = this.shadow.querySelector("#profile-menu");
    const sheetDropdownButton = this.shadow.querySelector("#sheets-button");
    const vngrdDropdownButton = this.shadow.querySelector("#vngrd-button");
    const profileButton = this.shadow.querySelector("#profile-button");

    if (!sheetDropdown.contains(e.target) && !sheetDropdownButton.contains(e.target)) {
      sheetDropdown.classList.add("hidden");
    }

    if (!vngrdDropdown.contains(e.target) && !vngrdDropdownButton.contains(e.target)) {
      vngrdDropdown.classList.add("hidden");
    }

    if (!profileMenu.contains(e.target) && !profileButton.contains(e.target)) {
      profileMenu.style.display = 'none';
    }
  }

  toggleProfileMenu(e) {
    e.stopPropagation();
    const menu = this.shadow.querySelector("#profile-menu");
    const isLoggedIn = menu.querySelector("#profileLink") && !menu.querySelector("#profileLink").classList.contains("hidden");
    if (isLoggedIn) {
      menu.style.display = menu.style.display === 'none' ? 'block' : 'none';
    } else {
      window.location.href = '/login';
    }
  }

  toggleMobileProfileMenu(e) {
    e.stopPropagation();
    const menu = this.shadow.querySelector("#mobile-profile-menu");
    const isLoggedIn = menu.querySelector("#mobileProfileLink") && !menu.querySelector("#mobileProfileLink").classList.contains("hidden");
    if (isLoggedIn) {
      menu.classList.toggle("hidden");
    } else {
      window.location.href = '/login';
    }
  }

  async getSessionProfile() {
    try {
      const response = await fetch(`/user-profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.isAuthenticated) {
        const loginText = data.displayName ? `Howdy, ${data.displayName}` :
          data.name ? `Howdy, ${data.name}` :
            'Howdy, Explorer';

        this.shadow.querySelector("#loginText").innerHTML = loginText;
        this.shadow.querySelector("#mobileLoginText").innerHTML = loginText;
        this.shadow.querySelector("#loginCaret").classList.remove("hidden");
        this.shadow.querySelector("#mobileLoginCaret").classList.remove("hidden");
        this.shadow.querySelector("#profileLink").classList.remove("hidden");
        this.shadow.querySelector("#logoutLink").classList.remove("hidden");
        this.shadow.querySelector("#mobileProfileLink").classList.remove("hidden");
        this.shadow.querySelector("#mobileLogoutLink").classList.remove("hidden");
        return true;
      } else {
        this.shadow.querySelector("#loginText").innerHTML = "Log in";
        this.shadow.querySelector("#mobileLoginText").innerHTML = "Log in";
        this.shadow.querySelector("#loginCaret").classList.add("hidden");
        this.shadow.querySelector("#mobileLoginCaret").classList.add("hidden");
        this.shadow.querySelector("#profileLink").classList.add("hidden");
        this.shadow.querySelector("#logoutLink").classList.add("hidden");
        this.shadow.querySelector("#mobileProfileLink").classList.add("hidden");
        this.shadow.querySelector("#mobileLogoutLink").classList.add("hidden");
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  render() {
    const mainClass = "rounded-md bg-gray-900 px-3 py-2 text-sm font-medium cyan-text future-font";
    const nonMainClass = "rounded-md px-3 py-2 text-xs font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font";
    const sheetClass = this.mainText === "sheet" ? mainClass : nonMainClass;
    const vngrdClass = this.mainText === "vngrd" ? mainClass : nonMainClass;
    const itemClass = this.mainText === "item" ? mainClass : nonMainClass;
    const loginClass = this.mainText === "profile" ? mainClass : nonMainClass;
    const wikiClass = nonMainClass;

    const mainMobileClass = "block rounded-md bg-gray-900 px-3 py-2 text-base font-medium cyan-text future-font";
    const nonMainMobileClass = "block rounded-md px-3 py-2 text-base font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font";
    const sheetClassMobile = this.mainText === "sheet" ? mainMobileClass : nonMainMobileClass;
    const vngrdClassMobile = this.mainText === "vngrd" ? mainMobileClass : nonMainMobileClass;
    const itemClassMobile = this.mainText === "item" ? mainMobileClass : nonMainMobileClass;
    const loginClassMobile = this.mainText === "profile" ? mainMobileClass : nonMainMobileClass;
    const wikiClassMobile = nonMainMobileClass;

    const template = html`
    <link
      href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;900&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Creepster&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"
      rel="stylesheet"
    />
    <link href="${navStyles}" rel="stylesheet" />
    <link href="${outputStyles}" rel="stylesheet" />
    <link href="${brandStyles}" rel="stylesheet" />
      <header class="bg-gray-800">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div class="flex lg:flex-1">
            <a href="/" class="-m-1.5 p-1.5">
              <span class="sr-only">Solaris</span>
              <img class="h-8 w-auto" src="${solarisLogo}" alt="">
            </a>
          </div>
          <div class="flex lg:hidden">
            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 dark-cyan-text" id="mobile-menu-button">
              <span class="sr-only">Open main menu</span>
              <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" id="burger-menu-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div class="hidden lg:flex lg:gap-x-12">
            <div class="relative">
              <button type="button" class="flex items-center gap-x-1 text-sm/6 font-semibold ${sheetClass}" aria-expanded="false" id="sheets-button">
                Sheets
                <svg class="size-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                </svg>
              </button>
              <div class="absolute top-full -left-8 highZ mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-gray-900 ring-1 shadow-lg ring-gray-900/5 lowerZ hidden" id="sheet-dropdown">
                <div class="p-4">
                  <div class="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-700">
                    <div class="flex-auto">
                      <a href="/character-creator" class="block font-semibold dark-cyan-text hover:cyan-text future-font">
                        Standard Character Creator
                        <span class="absolute inset-0"></span>
                      </a>
                      <p class="mt-1 future-font dark-cyan-text">The chracter sheet/ creator for the standard version of Solaris.</p>
                    </div>
                  </div>
                  <div class="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-700">
                    <div class="flex-auto">
                      <a href="/ship-creator" class="block font-semibold dark-cyan-text hover:cyan-text future-font">
                        Ship Creator
                        <span class="absolute inset-0"></span>
                      </a>
                      <p class="mt-1 future-font dark-cyan-text">The ship sheet/ creator for all versions of Solaris.</p>
                    </div>
                  </div>
                  <div class="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-700">
                    <div class="flex-auto">
                      <a href="/call-of-the-horizon" class="block font-semibold dark-cyan-text hover:cyan-text future-font">
                        Call of the Horizon
                        <span class="absolute inset-0"></span>
                      </a>
                      <p class="mt-1 future-font dark-cyan-text">The chracter sheet/ creator for the one-shot adventure supplement.</p>
                    </div>
                  </div>
                  <div class="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-700">
                    <div class="flex-auto">
                      <a href="/grasp-of-the-void" class="block font-semibold dark-cyan-text hover:cyan-text future-font">
                        Grasp of the Void
                        <span class="absolute inset-0"></span>
                      </a>
                      <p class="mt-1 future-font dark-cyan-text">The chracter sheet/ creator for the one-shot horror supplement.</p>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-600">
                  <a href="#" class="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-100 hover:bg-gray-500 future-font">
                    <svg class="size-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm6.39-2.908a.75.75 0 0 1 .766.027l3.5 2.25a.75.75 0 0 1 0 1.262l-3.5 2.25A.75.75 0 0 1 8 12.25v-4.5a.75.75 0 0 1 .39-.658Z" clip-rule="evenodd" />
                    </svg>
                    Watch Tutorial (Coming Soon!)
                  </a>
                  <a href="https://patreon.com/discoversolaris" target="_blank" class="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-100 hover:bg-gray-500 future-font">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                    </svg>
                    Become an Explorer
                  </a>
                </div>
              </div>
            </div>

            <a href="/explorer-item-creator" class="text-sm/6 font-semibold ${itemClass}">Item Creator</a>

            <div class="relative">
              <button type="button" class="flex items-center gap-x-1 text-sm/6 font-semibold ${vngrdClass}" aria-expanded="false" id="vngrd-button">
                Vanguard Commander
                <svg class="size-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                </svg>
              </button>

              <div class="absolute top-full -left-8 highZ mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-gray-900 ring-1 shadow-lg ring-gray-900/5 lowerZ hidden" id="vngrd-dropdown">
                <div class="p-4">
                  <div class="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-700">
                    <div class="flex-auto">
                      <a href="/vngrd-party" class="block font-semibold dark-cyan-text hover:cyan-text future-font">
                        Party Manager
                        <span class="absolute inset-0"></span>
                      </a>
                      <p class="mt-1 future-font dark-cyan-text">Create and manage your party invites.</p>
                    </div>
                  </div>
                  <div class="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-700">
                    <div class="flex-auto">
                      <a href="/vngrd-invite" class="block font-semibold dark-cyan-text hover:cyan-text future-font">
                        Join Party
                        <span class="absolute inset-0"></span>
                      </a>
                      <p class="mt-1 future-font dark-cyan-text">Use an invite code sent to you by a Vanguard Commander.</p>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-600">
                  <a href="#" class="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-100 hover:bg-gray-500 future-font">
                    <svg class="size-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm6.39-2.908a.75.75 0 0 1 .766.027l3.5 2.25a.75.75 0 0 1 0 1.262l-3.5 2.25A.75.75 0 0 1 8 12.25v-4.5a.75.75 0 0 1 .39-.658Z" clip-rule="evenodd" />
                    </svg>
                    Watch Tutorial (Coming Soon!)
                  </a>
                  <a href="https://patreon.com/discoversolaris" target="_blank" class="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-100 hover:bg-gray-500 future-font">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                    </svg>
                    Become a Vanguard Commander
                  </a>
                </div>
              </div>
            </div>
            
            <a href="https://planetaria.wiki" target="_blank" class="text-sm/6 font-semibold ${wikiClass}">Wikiplanetaria</a>

          </div>
          <div class="hidden lg:flex lg:flex-1 lg:justify-end">
            <div class="relative">
              <button class="flex items-center gap-x-1 text-sm/6 font-semibold dark-cyan-text hover:cyan-text ${loginClass}" id="profile-button">
                <span id="loginText">Log in</span>
                <svg class="size-5 flex-none text-gray-400 hidden" id="loginCaret" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only">User menu</span>
              </button>
              <div class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" id="profile-menu" style="display:none">
                <a href="/profile" class="block px-4 py-2 text-sm future-font hover:bg-gray-700 dark-cyan-text hover:cyan-text" id="profileLink">Profile</a>
                <a href="/logout" class="block px-4 py-2 text-sm future-font hover:bg-gray-700 dark-cyan-text hover:cyan-text" id="logoutLink">Logout</a>
              </div>
            </div>
          </div>
        </nav>
        
        <div class="lg:hidden highZ hidden" role="dialog" aria-modal="true" id="mobile-menu">
          <div class="fixed inset-0 highZ"></div>
          <div class="fixed inset-y-0 right-0 highZ w-full overflow-y-auto bg-gray-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div class="flex items-center justify-between">
              <a href="/" class="-m-1.5 p-1.5">
                <span class="sr-only">Solaris</span>
                <img class="h-8 w-auto" src="${solarisLogo}" alt="">
              </a>
              <button type="button" class="-m-2.5 rounded-md p-2.5 dark-cyan-text" id="close-menu-icon">
                <span class="sr-only">Close menu</span>
                <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="space-y-2 py-6">
                  <div class="-mx-3">
                    <button type="button" class="flex w-full items-center justify-between rounded-lg py-2 pr-3.5 pl-3 text-base/7 font-semibold ${sheetClassMobile}" aria-controls="disclosure-1" aria-expanded="false" id="sheets-mobile-button">
                      Sheets
                      <svg class="size-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    <div class="mt-2 space-y-2 hidden" id="sheet-mobile-dropdown">
                      <a href="/character-creator" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Standard Character Creator</a>
                      <a href="/ship-creator" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Ship Creator</a>
                      <a href="/call-of-the-horizon" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Call of the Horizon</a>
                      <a href="/grasp-of-the-void" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Grasp of the Void</a>
                    </div>
                  </div>

                  <a href="/explorer-item-creator" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold ${itemClassMobile}">Item Creator</a>
                  
                  <div class="-mx-3">
                    <button type="button" class="flex w-full items-center justify-between rounded-lg py-2 pr-3.5 pl-3 text-base/7 font-semibold ${vngrdClassMobile}" aria-controls="disclosure-2" aria-expanded="false" id="vngrd-mobile-button">
                      Vanguard Commander
                      <svg class="size-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    <div class="mt-2 space-y-2 hidden" id="vngrd-mobile-dropdown">
                      <a href="/vngrd-party" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Party Manager</a>
                        <a href="/vngrd-invite" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Join Party</a>
                      </div>
                    </div>
                  </div>

                  <a href="https://planetaria.wiki" target="_blank" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold ${wikiClassMobile}">Wikiplanetaria</a>

                  <div class="-mx-3">
                    <button type="button" id="mobile-profile-button" class="flex w-full items-center justify-between rounded-lg py-2 pr-3.5 pl-3 text-base/7 font-semibold ${loginClassMobile}">
                      <span id="mobileLoginText">Log in</span>
                      <svg class="size-5 flex-none" id="mobileLoginCaret" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    <div class="space-y-2 hidden" id="mobile-profile-menu">
                      <a href="/profile" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font" id="mobileProfileLink">Profile</a>
                      <a href="/logout" class="block rounded-lg py-2 pr-3 pl-6 text-sm/7 font-semibold dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font" id="mobileLogoutLink">Logout</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="glow-line"></div>
      </header>
    `;

    render(template, this.shadow);
    this.getSessionProfile();
  }
}

customElements.define("nav-bar", NavBar);
